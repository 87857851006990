import { Injectable } from '@angular/core';
import { StorageItem } from './storage-item.type';

const STORAGE_PREFIX = 'tas';

@Injectable({
  providedIn: 'root'
})

export class StorageService {

  constructor() {
  }

  private get storage(): Storage {
    return localStorage;
  }

  clearAllLocalStorage() {
    this.storage.clear();
  }

  getItem(name: StorageItem): string | null {
    return this.storage.getItem(`${STORAGE_PREFIX}_${name}`);
  }

  getItemJson<T>(name: StorageItem): T {
    return JSON.parse(this.storage.getItem(`${STORAGE_PREFIX}_${name}`) ?? '{}') as T;
  }

  setItem(name: StorageItem, value: any, json: boolean = false): void {
    if (json) return this.storage.setItem(`${STORAGE_PREFIX}_${name}`, JSON.stringify(value));

    return this.storage.setItem(`${STORAGE_PREFIX}_${name}`, value);
  }

  deleteItem(name: StorageItem): void {
    return this.storage.removeItem(`${STORAGE_PREFIX}_${name}`);
  }
}
