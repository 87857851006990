// USA
export const locale = {
  lang: 'en',
  data: {
    TRANSLATOR: {
      SELECT: 'Select your language',
    },
    MENU: {
      NEW: 'new',
      ACTIONS: 'Actions',
      CREATE_POST: 'Create New Post',
      PAGES: 'Pages',
      FEATURES: 'Features',
      APPS: 'Apps',
      DASHBOARD: 'Dashboard',
      EMISSIONS: 'Emissions',
      QUOTES: 'Quotes',
      MODIFICATIONS: 'Querys',
      PLANS: 'Plans',
      REPORTS: 'Reports',
      SURCHARGES: 'Surcharges',
      COMPARISON: 'Comparison',
      CUSTOMREPORT: 'Personal Report',
      GENERALREPORT: 'General Report',
      DISCOUNTCODES: 'Discount Codes',
      PVP: 'PVP',
      CONTRACTS: 'Contracts',
      PARAMETERS: 'Parameters',
      USER_MANAGEMENT: 'User Management',
      AGENCIES: 'Agencies',
      USERS: 'Users',
      ROLES: 'Roles',
      PERMISSIONS: 'Permissions',
      CONDITIONED: 'Conditioned',

      BLOG: 'Blog',
      AUTHORS: 'Authors',
      POSTS: 'Posts',
      CATEGORIES: 'Categories',
      NEWSLETTERS: 'Newsletters',
      HEADERS: 'Headers',
      CENTRAL: 'Central',
      FORMULAS: 'Formulas',

      MESSAGEBIRD: 'Message Bird',
      CSAT: 'CSAT Survey',
      NPS: 'NPS Survey',

      LANDING: 'Landing Pages',
      LANDINGPAGES: 'Pages',
    },
    AUTH: {
      GENERAL: {
        OR: 'Or',
        SUBMIT_BUTTON: 'Submit',
        NO_ACCOUNT: 'Don\'t have an account?',
        SIGNUP_BUTTON: 'Sign Up',
        FORGOT_BUTTON: 'Forgot Password',
        BACK_BUTTON: 'Back',
        PRIVACY: 'Privacy',
        LEGAL: 'Legal',
        CONTACT: 'Contact',
      },
      LOGIN: {
        TITLE: 'Login Account',
        BUTTON: 'Sign In',
      },
      FORGOT: {
        TITLE: 'Forgotten Password?',
        DESC: 'Enter your email to reset your password',
        SUCCESS: 'Your account has been successfully reset.'
      },
      REGISTER: {
        TITLE: 'Sign Up',
        DESC: 'Enter your details to create your account',
        SUCCESS: 'Your account has been successfuly registered.'
      },
      INPUT: {
        EMAIL: 'Email',
        FULLNAME: 'Fullname',
        PASSWORD: 'Password',
        CONFIRM_PASSWORD: 'Confirm Password',
        USERNAME: 'Username'
      },
      VALIDATION: {
        INVALID: '{{name}} is not valid',
        REQUIRED: '{{name}} is required',
        MIN_LENGTH: '{{name}} minimum length is {{min}}',
        AGREEMENT_REQUIRED: 'Accepting terms & conditions are required',
        NOT_FOUND: 'The requested {{name}} is not found',
        INVALID_LOGIN: 'The login detail is incorrect',
        REQUIRED_FIELD: 'Required field',
        MIN_LENGTH_FIELD: 'Minimum field length:',
        MAX_LENGTH_FIELD: 'Maximum field length:',
        INVALID_FIELD: 'Field is not valid',
      }
    },
    ECOMMERCE: {
      COMMON: {
        SELECTED_RECORDS_COUNT: 'Selected records count: ',
        ALL: 'All',
        SUSPENDED: 'Suspended',
        ACTIVE: 'Active',
        FILTER: 'Filter',
        BY_STATUS: 'by Status',
        BY_TYPE: 'by Type',
        BUSINESS: 'Business',
        INDIVIDUAL: 'Individual',
        SEARCH: 'Search',
        IN_ALL_FIELDS: 'in all fields'
      },
      ECOMMERCE: 'eCommerce',
      CUSTOMERS: {
        CUSTOMERS: 'Customers',
        CUSTOMERS_LIST: 'Customers list',
        NEW_CUSTOMER: 'New Customer',
        DELETE_CUSTOMER_SIMPLE: {
          TITLE: 'Customer Delete',
          DESCRIPTION: 'Are you sure to permanently delete this customer?',
          WAIT_DESCRIPTION: 'Customer is deleting...',
          MESSAGE: 'Customer has been deleted'
        },
        DELETE_CUSTOMER_MULTY: {
          TITLE: 'Customers Delete',
          DESCRIPTION: 'Are you sure to permanently delete selected customers?',
          WAIT_DESCRIPTION: 'Customers are deleting...',
          MESSAGE: 'Selected customers have been deleted'
        },
        UPDATE_STATUS: {
          TITLE: 'Status has been updated for selected customers',
          MESSAGE: 'Selected customers status have successfully been updated'
        },
        EDIT: {
          UPDATE_MESSAGE: 'Customer has been updated',
          ADD_MESSAGE: 'Customer has been created'
        }
      }
    },
    MODIFICATION: {
      ANULATION: 'Anulation',
      STANDBY: 'Stand-By',
      MOTIVE: 'Motive',
      CANCEL: 'Cancel',
      ACTIVE: 'Active',
      NULLED: 'Nulled',
      UPDATE: 'Update Customer',
      CHANGESTATUS: 'Change Status',
      DETAIL: 'See Detail',
      DOCUMENTS: 'See Documents',
      COMMENTS: 'Comments'
    },
    REPORTS: {
      HEADERS: {
        ParentId: 'ID Padre',
        ContractId: 'Contrato',
        CreationTime: 'Fecha de Creacion',
        LastModificationTime: 'Ultima Modificación',
        QuotationType: 'Tipo de Cotizacion',
        PlanName: 'Plan',
        TenantId: 'Tenant',
        TenantName: 'Compañia',
        PaymentReference: 'Referencia de Pago',
        PaymentEngineName: 'Tipo de Pago',
        PriceWithoutDiscount: 'Precio sin Descuento',
        PriceWithDiscount: 'Precio con Descuento',
        CollectedValue: 'Valor Recaudo',
        CommissionValue: 'Comision',
        CollectedValueParentAgency: 'Valor Recaudo (Agencia Padre)',
        CommissionValueParentAgency: 'Comision (Agencia Padre)',
        DiscountCode: 'Codigo de Descuento',
        Name: 'Nombre',
        LastName: 'Apellido',
        Email: 'Correo',
        Phone: 'Telefono',
        Origin: 'Origen',
        Destiny: 'Destino',
        HashCode: 'HashCode',
        Pax: 'Pasajero #',
        BirthDate: 'Fecha de Nacimiento',
        DepartureDate: 'Fecha de Salida',
        ArrivalDate: 'Fecha de Regreso',
        TravelDayLength: 'Dias de Viaje',
        Status: 'Estatus',
        Motive: 'Motivo',
        CustomerId: 'ID Cliente',
        Code: '#Poliza',
        CustomerDocument: 'DNI',
        TotalPassenger: '#Pasajeros',
        AgencyJr: 'Agencia Jr',
        AdminJr: 'Admin Jr',
        Counter: 'Counter',
        IssuingUser: 'IssuingUser',
        TypeClient: 'Type Client',
        Adviser: 'Adviser',
        'Super Admin': 'Super Admin',
        PhoneNumber: 'Número de Teléfono',
        EmergencyPhone: 'Número de Emergencia',
        EmergencyName: 'Contacto de Emergencia',
        PriceWithoutModifiers:'Precio sin Modificaciones',
        Price:'Precio',
        IndividualPrice: 'Precio por pasajero',
        PlanId: 'Plan',
        DestinyId: 'Destino',
        OriginId:'Origen',
        ExchangeRate:'Tasa de cambio',
      }
    }

  }
};
