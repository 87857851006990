import { environment } from '@env/environment';
import { AuthModel } from './auth.model';

declare const moment: any;

export class UserModel extends AuthModel {
  id: number;
  pic: string;
  email: string;
  phone: string;
  roles: number[];
  fullname: string;
  password: string;
  lastname: string;
  parentId: number;
  DataBankId: number;
  tenantId: number;
  tenantSource: string;
  username: string;
  isDelted: boolean
  language: string;
  timeZone: string;
  isActive: boolean;
  firstname: string;
  creationTime: any;
  lastModifierUserId: number;
  lastModificationTime: any;
  isB2B: boolean;
  tenantCode: string;
  companyId: number;
  companyName: string;

  setUser(_user: unknown) {
    const user = _user as any;

    this.id = user.id;
    this.email = user.emailAddress || '';
    this.roles = [user.roleId];
    this.lastname = user.surname;
    this.parentId = user.parentId;
    this.DataBankId = user.dataBankId;
    this.tenantId = user.tenantId;
    this.username = user.userName;
    this.isDelted = user.isDelted;
    this.isActive = user.isActive;
    this.password = user.password || '';
    this.fullname = `${user.name} ${user.surname}`;
    this.firstname = user.name;
    this.phone = user.phoneNumber || '';
    this.creationTime = moment(user.creationTime);
    this.lastModifierUserId = user.lastModifierUserId;
    this.lastModificationTime = moment(user.lastModificationTime);
    this.pic = `https://ui-avatars.com/api/?name=${this.fullname}&color=7F9CF5&background=EBF4FF`;
    this.timeZone = '-5:00';
    this.language = 'es';
    this.isB2B = user.isB2B;
    this.tenantSource = user.tenantId === 5 ? environment.ASNSource : user.tenantId === 4 ? environment.SDVMSource : environment.TASSource;
    this.tenantCode = this.getcode(user.tenantId);
    this.companyId = user.companyId;
    this.companyName = user.companyName;
  }

  getcode(tenantId: number) {
    switch (tenantId) {
      case 3:
        return 'tas'
      case 4:
        return 'sdvm'
      case 5:
        return 'asn'
      case 6:
        return 'vtc'
      default:
        return ''
    }
  }
}

export class DataBankModel {
  DataBankId: number;
  Account: number;
  AccountType: string;
  BankName: string;
}
