export class AuthModel {
  IsB2B: boolean;
  UserId: number;
  RoleName: string;
  TenantId: number;
  PermissionRole: string[]

  // authToken: string;
  // refreshToken: string;
  // expiresIn: Date;

  setAuth(auth: AuthModel) {
    this.UserId = auth.UserId;
    this.RoleName = auth.RoleName;
    this.TenantId = auth.TenantId;
    this.PermissionRole = auth.PermissionRole;
    this.IsB2B = auth.IsB2B;
  }
}
