// Spain
export const locale = {
  lang: 'es',
  data: {
    TRANSLATOR: {
      SELECT: 'Elige tu idioma',
    },
    MENU: {
      NEW: 'nuevo',
      ACTIONS: 'Comportamiento',
      CREATE_POST: 'Crear nueva publicación',
      PAGES: 'Pages',
      FEATURES: 'Caracteristicas',
      APPS: 'Aplicaciones',
      DASHBOARD: 'Tablero',
      EMISSIONS: 'Emisiones',
      QUOTES: 'Cotizaciones',
      MODIFICATIONS: 'Consulta',
      PLANS: 'Planes',
      REPORTS: 'Reportes',
      SURCHARGES: 'Recargos',
      COMPARISON: 'Cotejos',
      CUSTOMREPORT: 'Reporte Personalizado',
      GENERALREPORT: 'Reporte General',
      DISCOUNTCODES: 'Codigos de Descuento',
      PVP: 'PVP',
      CONTRACTS: 'Contratos',
      PARAMETERS: 'Parametros',
      CONDITIONED: 'Condicionado',
      CONTRACTSEXPIRED: 'Contratos a expirar',

      BLOG: 'Blogs',
      AUTHORS: 'Autores',
      POSTS: 'Posts',
      CATEGORIES: 'Categorias',
      NEWSLETTERS: 'Suscripciones',
      HEADERS: 'Headers',

      USER_MANAGEMENT: 'Gestión de usuarios',
      AGENCIES: 'Agencias',
      USERS: 'Usuarios',
      ROLES: 'Roles',
      PERMISSIONS: 'Permisos',

      MESSAGEBIRD: 'Message Bird',
      CSAT: 'Encuesta CSAT',
      NPS: 'Encuesta NPS',

      LANDING: 'Landings',
      LANDINGPAGES: 'Gestión de Landings',

      DOCTORS: 'Médicos',
      PRESCRIPTIONS: 'Crear recetas'
    },
    AUTH: {
      GENERAL: {
        OR: 'O',
        SUBMIT_BUTTON: 'Enviar',
        NO_ACCOUNT: 'No tienes una cuenta?',
        SIGNUP_BUTTON: 'Regístrate',
        FORGOT_BUTTON: 'Se te olvidó tu contraseña',
        BACK_BUTTON: 'Espalda',
        PRIVACY: 'Intimidad',
        LEGAL: 'Legal',
        CONTACT: 'Contacto',
      },
      LOGIN: {
        TITLE: 'Crear una cuenta',
        BUTTON: 'Registrarse',
      },
      FORGOT: {
        TITLE: 'Contraseña olvidada?',
        DESC: 'Ingrese su correo electrónico para restablecer su contraseña',
        SUCCESS: 'Your account has been successfully reset.'
      },
      REGISTER: {
        TITLE: 'Sign Up',
        DESC: 'Enter your details to create your account',
        SUCCESS: 'Your account has been successfuly registered.'
      },
      INPUT: {
        EMAIL: 'Email',
        FULLNAME: 'Fullname',
        PASSWORD: 'Password',
        CONFIRM_PASSWORD: 'Confirm Password',
        USERNAME: 'Usuario'
      },
      VALIDATION: {
        INVALID: '{{name}} is not valid',
        REQUIRED: '{{name}} is required',
        MIN_LENGTH: '{{name}} minimum length is {{min}}',
        AGREEMENT_REQUIRED: 'Accepting terms & conditions are required',
        NOT_FOUND: 'The requested {{name}} is not found',
        INVALID_LOGIN: 'The login detail is incorrect',
        REQUIRED_FIELD: 'Required field',
        MIN_LENGTH_FIELD: 'Minimum field length:',
        MAX_LENGTH_FIELD: 'Maximum field length:',
        INVALID_FIELD: 'Field is not valid',
      }
    },
    ECOMMERCE: {
      COMMON: {
        SELECTED_RECORDS_COUNT: 'Selected records count: ',
        ALL: 'All',
        SUSPENDED: 'Suspended',
        ACTIVE: 'Active',
        FILTER: 'Filter',
        BY_STATUS: 'by Status',
        BY_TYPE: 'by Type',
        BUSINESS: 'Business',
        INDIVIDUAL: 'Individual',
        SEARCH: 'Search',
        IN_ALL_FIELDS: 'in all fields'
      },
      ECOMMERCE: 'eCommerce',
      CUSTOMERS: {
        CUSTOMERS: 'Customers',
        CUSTOMERS_LIST: 'Customers list',
        NEW_CUSTOMER: 'New Customer',
        DELETE_CUSTOMER_SIMPLE: {
          TITLE: 'Customer Delete',
          DESCRIPTION: 'Are you sure to permanently delete this customer?',
          WAIT_DESCRIPTION: 'Customer is deleting...',
          MESSAGE: 'Customer has been deleted'
        },
        DELETE_CUSTOMER_MULTY: {
          TITLE: 'Customers Delete',
          DESCRIPTION: 'Are you sure to permanently delete selected customers?',
          WAIT_DESCRIPTION: 'Customers are deleting...',
          MESSAGE: 'Selected customers have been deleted'
        },
        UPDATE_STATUS: {
          TITLE: 'Status has been updated for selected customers',
          MESSAGE: 'Selected customers status have successfully been updated'
        },
        EDIT: {
          UPDATE_MESSAGE: 'Customer has been updated',
          ADD_MESSAGE: 'Customer has been created'
        }
      }
    },
    MODIFICATION: {
      ANULATION: 'Anulación',
      STANDBY: 'Stand-By',
      MOTIVE: 'Motivo',
      CANCEL: 'Anular',
      ACTIVE: 'Activo',
      NULLED: 'Anulado',
      UPDATE: 'Actualizar Asegurado',
      CHANGESTATUS: 'Cambiar Status',
      DETAIL: 'Ver Status',
      DOCUMENTS: 'Ver Documentos',
      COMMENTS: 'Comentarios'
    },
    PLAN: {
      CONSTRAINTS: {
        Age: 'Edad',
        DestinyId: 'Destino',
        IsNational: 'Nacionales',
        DayCountMinimal: 'Limite Minimo de Días',
        DestinyAllowed: 'Destinos permitidos',
        DomainAllowed: 'Dominios permitidos'
      }
    },
    REPORTS: {
      HEADERS: {
        ParentId: 'ID Usuario',
        ContractId: 'Contrato',
        CreationTime: 'Fecha de Creacion',
        LastModificationTime: 'Ultima Modificación',
        QuotationType: 'Tipo de Cotizacion',
        PlanName: 'Plan',
        TenantId: 'Tenant',
        TenantName: 'Compañia',
        PaymentReference: 'Referencia de Pago',
        PaymentEngineName: 'Tipo de Pago',
        PriceWithoutDiscount: 'Precio sin Descuento',
        PriceWithDiscount: 'Precio con Descuento',
        CollectedValue: 'Valor Recaudo',
        CommissionValue: 'Comision',
        CollectedValueParentAgency: 'Valor Recaudo (Agencia Padre)',
        CommissionValueParentAgency: 'Comision (Agencia Padre)',
        DiscountCode: 'Codigo de Descuento',
        Name: 'Nombre',
        LastName: 'Apellido',
        Email: 'Correo',
        Phone: 'Telefono',
        Origin: 'Origen',
        Destiny: 'Destino',
        HashCode: 'HashCode',
        Pax: 'Pasajero #',
        BirthDate: 'Fecha de Nacimiento',
        DepartureDate: 'Fecha de Salida',
        ArrivalDate: 'Fecha de Regreso',
        TravelDayLength: 'Dias de Viaje',
        Status: 'Estatus',
        Motive: 'Motivo',
        CustomerId: 'ID Cliente',
        Code: '#Poliza',
        CertificateCode: '#Poliza',
        CustomerDocument: 'DNI',
        OfficialDocument: 'DNI',
        TotalPassenger: '#Pasajeros',
        AgencyJr: 'Agencia Jr',
        AdminJr: 'Admin Jr',
        Counter: 'Counter',
        IssuingUser: 'Usuario Emisor',
        TypeClient: 'Tipo de Cliente',
        Adviser: 'Adviser',
        'Super Admin': 'Super Admin',
        PhoneNumber: 'Número de Teléfono',
        EmergencyPhone: 'Número de Emergencia',
        EmergencyName: 'Contacto de Emergencia',
        PriceWithoutModifiers: 'Precio sin Modificaciones',
        Price: 'Precio',
        IndividualPrice: 'Precio por pasajero',
        PerksPrice: 'Precio Beneficio',
        PlanId: 'Plan',
        DestinyId: 'Destino',
        OriginId: 'Origen',
        ExchangeRate: 'Tasa de cambio',
        Agency: 'Agencia',
        SubAgency: 'SubAgencia',
        PaymentDate: 'Fecha de pago',
        Role: 'Rol',
        UserChangeStatus: 'Usuario Anulacion',
        CustomerComment: 'Comentario anulacion',
        DateAnnulment: 'Fecha de anulacion',
        IsMultitrip: 'Es multitrip',
        TypeMultitrip: 'Tipo Multitrip',
        ConfirmedEntry: 'Ingreso Confirmado',
        AssuredPayment: 'Pago Asegurado',
        DestinyConstrain: "Restriccion por Destino",
        DomainConstrain: "Restriccion por Dominio",
        OriginConstrain: "Restriccion por Origen",
        CharacterChanges: 'Cambios de Caracteres (Nombres y Apellidos)'
      }
    }
  }
};
