import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { environment } from '@env/environment';
import { ApiAuthService } from '../services/api-auth/api-auth.service';

/**
 * Prefixes all requests not starting with `http[s]` with `environment.apiUrl`.
 */
@Injectable({
  providedIn: 'root',
})
export class ApiPrefixInterceptor implements HttpInterceptor {
  private token: string | null = null;

  constructor(private _auth: ApiAuthService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (
      request.url.indexOf('assets') > -1 ||
      request.url.indexOf('api-blog') > -1 ||
      request.url.indexOf('api-landings') > -1 ||
      request.url.indexOf('mb.pervolare.net') > -1 ||
      request.url.indexOf('mbdev.pervolare.net') > -1 ||
      request.url.indexOf('api.ipify.org') > -1 ||
      request.url.indexOf('api.cloudinary') > -1 ||
      request.url.indexOf('directus-master') > -1 
    ) return next.handle(request);

    if (!/^(http|https):/i.test(request.url)) {
      request = request.clone({ url: environment.apiUrl + request.url });
    }

    let keyApi = environment.apikeys.general;
    this.setToken();

    const headers: any = {
      'Content-Type': 'application/json',
      'Ocp-Apim-Trace': 'true',
    };

    if (request.url.indexOf(`${environment.apiUrl}/payment`) > -1) {
      keyApi = environment.apikeys.payments;
    }

    headers[environment.production ? 'key-api-prod' : 'key-api'] = keyApi;

    request = request.clone({ setHeaders: headers });


    if (request.url.indexOf('token') > -1) return next.handle(request);
    else {
      request = request.clone({
        setHeaders: {
          authorization: `Bearer ${this.token}`,
        },
      });
    }

    return next.handle(request);
  }

  private setToken() {
    if (this._auth.hasToken()) {
      this.token = this._auth.getToken();
    }
  }
}
